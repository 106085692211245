import { Link, useNavigate, useLocation } from "react-router-dom";
import DropdownButton from "components/ux/dropdown-button/DropdownButton";

const NavbarItems = ({ isAuthenticated, onHamburgerMenuToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => navigate("/login");

  const dropdownOptions = [
    { name: "Profile", onClick: () => navigate("/user-profile") },
    { name: "Logout", onClick: handleLogout },
  ];

  const isActive = (path) => location.pathname === path;

  return (
    <>
      {["/", "/contact", "/solutions", "/about-us", "/terms"].map((path, index) => (
        <li key={index} className="transition duration-300">
          <Link
            to={path}
            className={`uppercase tracking-wide text-lg font-medium ${
              isActive(path) ? "text-white" : "text-gray-200 hover:text-white"
            }`}
            onClick={onHamburgerMenuToggle}
          >
            {path === "/" ? "Home" : path.substring(1).replace("-", " ")}
          </Link>
        </li>
      ))}
      <li>
        {isAuthenticated ? (
          <DropdownButton triggerType="click" options={dropdownOptions} />
        ) : (
          <Link
            to="/login"
            className="uppercase text-lg tracking-wide text-gray-200 hover:text-white bg-red-600 px-5 py-3 rounded-lg shadow hover:shadow-md transition duration-300"
            onClick={onHamburgerMenuToggle}
          >
            Login/Register
          </Link>
        )}
      </li>
    </>
  );
};

export default NavbarItems;
