import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faHotel,
  faPlane,
  faHeart,
  faBusinessTime,
  faSuitcase,
  faHandshake,
  faGift,
} from "@fortawesome/free-solid-svg-icons";

import banner from "assets/hero.jpg";
import aboutUsImage from "assets/aboutUsImage.jpg";
import familyVacation from "assets/familyTravel.jpg";
import businessTravel from "assets/businessTrip.png";
import luxuryTrip from "assets/luxuryExperience.jpg";
import travelSupport from "assets/travelSupport.avif";
import corporateServices from "assets/corporateServices.webp";

const Home = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate("/contact");
  };

  const testimonials = [
    {
      quote:
        "travelroy revolutionized our travel operations. Their expertise and solutions have been a game-changer for our business!",
      author: "David B., Global Solutions Group",
    },
    {
      quote:
        "We trust travelroy for efficient travel management, whether it's for our teams or leisure travelers.",
      author: "Sophia M., Enterprise Solutions",
    },
    {
      quote:
        "A complete travel partner. Their support and tools are exceptional for both corporate and personal needs.",
      author: "Lucas R., Worldwide Enterprises",
    },
    {
      quote:
        "With travelroy, we streamline bookings and offer our clients the best experiences every time.",
      author: "Isabella T., Luxury Experiences Ltd.",
    },
  ];

  const services = [
    {
      icon: faGlobe,
      title: "Global Business Travel Solutions",
      description:
        "Access seamless global booking for flights, hotels, and tailored corporate travel solutions. Everything your business needs to succeed.",
    },
    {
      icon: faHotel,
      title: "Global Hotel Reservations",
      description:
        "Book and manage global hotel accommodations, from budget stays to luxury resorts, across all your destinations.",
    },
    {
      icon: faPlane,
      title: "Airline Management & Booking",
      description:
        "Simplify flight bookings and manage schedules efficiently for your entire team or clients, anytime, anywhere.",
    },
    {
      icon: faHeart,
      title: "24/7 Dedicated Support",
      description:
        "Our expert support team is available around the clock to ensure a smooth travel experience for your clients.",
    },
    {
      icon: faBusinessTime,
      title: "Corporate Travel Packages",
      description:
        "Tailored travel packages for your employees, designed to maximize business efficiency while offering premium comfort.",
    },
    {
      icon: faSuitcase,
      title: "Exclusive Travel Deals",
      description:
        "Gain access to customized travel packages, including last-minute deals, corporate retreats, and family vacations.",
    },
  ];

  const newFeatures = [
    {
      image: familyVacation,
      title: "Family-Focused Getaways",
      description:
        "Create unforgettable family vacations with personalized packages designed for all ages and preferences.",
    },
    {
      image: businessTravel,
      title: "Business Travel Simplified",
      description:
        "Effortlessly manage your business trips, from booking to managing itineraries, with our comprehensive solutions.",
    },
    {
      image: luxuryTrip,
      title: "Premium Travel Services",
      description:
        "Experience the best in luxury travel with exclusive access to private jets, five-star resorts, and bespoke tours.",
    },
    {
      image: travelSupport,
      title: "24/7 Travel Assistance",
      description:
        "Our dedicated support is available any time to resolve issues and provide travel assistance when you need it most.",
    },
    {
      image: corporateServices,
      title: "Corporate Retreats & Incentive Programs",
      description:
        "Offer your employees and clients unforgettable corporate retreats and incentive programs at exclusive destinations.",
    },
  ];

  const faq = [
    {
      question: "How can I book flights for my team?",
      answer:
        "You can book and manage flights easily through our platform, offering a range of search filters for specific needs.",
    },
    {
      question: "What types of hotels do you offer?",
      answer:
        "We provide a variety of accommodations from budget options to 5-star luxury hotels, worldwide.",
    },
    {
      question: "Do you have deals for corporate groups?",
      answer:
        "Yes, we offer custom packages and deals specifically for corporate groups, with exclusive offers for larger bookings.",
    },
    {
      question: "How can I customize a travel package?",
      answer:
        "You can customize travel packages on our platform by choosing destinations, activities, and accommodations tailored to your preferences.",
    },
    {
      question: "Are last-minute deals available?",
      answer:
        "Yes, we feature limited-time offers for last-minute bookings, designed to help you save on travel costs.",
    },
    {
      question: "Can I change my booking after confirmation?",
      answer:
        "Yes, you can modify your bookings, whether it's for flights, hotels, or tours, directly through our platform.",
    },
    {
      question: "What kind of support do you offer after booking?",
      answer:
        "We offer 24/7 support, ready to assist with any changes or issues that arise post-booking, ensuring peace of mind.",
    },
    {
      question: "Do you offer loyalty rewards?",
      answer:
        "Yes, we offer a loyalty program for frequent clients, providing special rewards and perks for recurring bookings.",
    },
    {
      question: "Is my payment information secure?",
      answer:
        "Your payment information is processed with industry-standard encryption and security protocols to ensure maximum protection.",
    },
    {
      question: "Can I get a personalized travel quote?",
      answer:
        "Yes, we offer bespoke travel quotes for businesses, groups, or individuals to meet your exact needs and preferences.",
    },
    {
      question: "Can I access travel services through your API?",
      answer:
        "Yes, our API integration allows seamless access to booking services, travel data, and management tools for your business needs.",
    },
  ];

  return (
    <div className="font-sans bg-white text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <img
          src={banner}
          alt="Travel with Confidence via travelroy"
          className="w-full h-[80vh] object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-red-800 to-transparent flex items-center justify-center text-center">
          <div className="max-w-3xl px-6">
            <h1 className="text-5xl font-bold text-white mb-6">
              Revolutionize Your Travel Business with travelroy
            </h1>
            <p className="text-lg text-gray-200 mb-8">
              Discover cutting-edge B2B travel solutions designed to streamline
              and optimize your travel operations.
            </p>
            <button
              onClick={handleContactClick}
              className="bg-red-600 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-red-700"
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>

      {/* About Us Section */}
      <section className="py-12 bg-red-50 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          About travelroy
        </h2>
        <div className="flex flex-col md:flex-row items-center max-w-5xl mx-auto">
          <img
            src={aboutUsImage}
            alt="About travelroy"
            className="w-full md:w-1/2 rounded-lg shadow-lg"
          />
          <div className="md:ml-8 mt-6 md:mt-0 text-left">
            <p className="text-lg text-gray-700">
              travelroy is your ultimate B2B partner in global travel management.
              We provide cutting-edge tools for agencies and businesses to
              streamline booking, manage accommodations, and offer tailored
              travel solutions worldwide.
            </p>
            <p className="mt-4 text-lg text-gray-700">
              Join the ranks of our satisfied partners and elevate your travel
              operations with our seamless technology and expert support.
            </p>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Our Core Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {services.map((service, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-md bg-white flex flex-col items-center"
            >
              <FontAwesomeIcon
                icon={service.icon}
                className="text-red-500 text-4xl mb-4"
              />
              <h3 className="text-xl font-medium text-gray-900 mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* New Features Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Explore Our New Features
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {newFeatures.map((feature, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-md bg-white"
            >
              <img
                src={feature.image}
                alt={feature.title}
                className="w-full h-40 object-cover rounded-md mb-4"
              />
              <h3 className="text-xl font-medium text-gray-900">
                {feature.title}
              </h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-12 bg-red-50 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Frequently Asked Questions
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {faq.map((item, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-md bg-white"
            >
              <h4 className="text-lg font-semibold text-red-700 mb-2">
                {item.question}
              </h4>
              <p className="text-gray-600">{item.answer}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          What Our Clients Say
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-md bg-white"
            >
              <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
              <p className="font-medium text-red-700">{testimonial.author}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Ready to Boost Your Travel Business?
        </h2>
        <p className="text-lg text-gray-700 mb-6">
          Join travelroy today and transform the way you manage travel bookings
          with the most advanced and efficient tools available.
        </p>
        <button
          onClick={handleContactClick}
          className="bg-red-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-700"
        >
          Get Started
        </button>
      </section>
    </div>
  );
};

export default Home;
