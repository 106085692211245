import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSuitcase,
  faGlobe,
  faUsers,
  faHotel,
  faShieldAlt,
  faLightbulb,
  faChartLine,
  faCheckCircle,
  faCompass,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";

import businessTravelImg from "assets/businessTravelImg.webp";
import uniqueSolutionsImg from "assets/uniqueSolutionsImg.png";
import hotelServicesImg from "assets/hotelServicesImg.avif";
import riskManagementImg from "assets/riskManagementImg.jpg";
import innovationImg from "assets/innovationImg.jpg";
import vipServicesImg from "assets/vipServicesImg.jpg";
import destinationExpertiseImg from "assets/destinationExpertiseImg.avif";

const Solutions = () => {
  const pricingPlans = [
    {
      title: "Basic",
      price: "$249/month",
      features: [
        "Access to global travel suppliers",
        "24/7 customer support",
        "Basic travel reports",
        "Essential booking management tools",
      ],
    },
    {
      title: "Advanced",
      price: "$499/month",
      features: [
        "All Basic features",
        "Comprehensive analytics & insights",
        "Customizable travel booking options",
        "Priority customer service",
        "Consultation with travel experts",
      ],
    },
    {
      title: "Premium",
      price: "Contact Us",
      features: [
        "All Advanced features",
        "Personalized travel planning services",
        "Custom integrations with your platform",
        "Exclusive partner deals",
        "Dedicated travel managers",
        "Global enterprise solutions",
      ],
    },
  ];

  return (
    <div className="font-sans bg-white text-gray-900">
      {/* Hero Section */}
      <div className="relative bg-red-800 text-white text-center py-24">
        <h1 className="text-6xl font-bold mb-4">Tailored Travel Solutions</h1>
        <p className="text-xl mb-6">
          Experience seamless travel management with our custom solutions
          designed for B2B clients. Let us elevate your business' travel
          experience with innovative tools and personalized services.
        </p>
        <button className="bg-red-700 text-white px-8 py-4 rounded-lg hover:bg-red-800 transition duration-300">
          Discover More
        </button>
      </div>

      {/* Key Solutions Section */}
      <section className="py-20 text-center bg-gray-50">
        <h2 className="text-4xl font-semibold text-red-700 mb-12">
          Our Premium Solutions
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 max-w-7xl mx-auto">
          {/* Business Travel Management */}
          <div className="p-8 border rounded-lg shadow-xl bg-white">
            <img
              src={businessTravelImg}
              alt="Business Travel Management"
              className="w-full h-48 object-cover rounded-md mb-6"
            />
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Corporate Travel Management
            </h3>
            <p className="text-gray-700 mb-4">
              Manage your business travel effortlessly with our customizable
              solutions that offer flexibility, safety, and convenience, all
              while maximizing your travel budget.
            </p>
            <button className="text-red-700 hover:text-red-800 transition duration-300">
              Learn More
            </button>
          </div>

          {/* Unique Travel Solutions */}
          <div className="p-8 border rounded-lg shadow-xl bg-white">
            <img
              src={uniqueSolutionsImg}
              alt="Unique Travel Solutions"
              className="w-full h-48 object-cover rounded-md mb-6"
            />
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Custom Travel Solutions
            </h3>
            <p className="text-gray-700 mb-4">
              From media to life sciences, our tailored travel services address
              the unique needs of your industry, ensuring that your team gets
              the best travel experience.
            </p>
            <button className="text-red-700 hover:text-red-800 transition duration-300">
              Learn More
            </button>
          </div>

          {/* Hotel Services */}
          <div className="p-8 border rounded-lg shadow-xl bg-white">
            <img
              src={hotelServicesImg}
              alt="Hotel Services"
              className="w-full h-48 object-cover rounded-md mb-6"
            />
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Exclusive Hotel Partnerships
            </h3>
            <p className="text-gray-700 mb-4">
              Secure the best rates, exclusive discounts, and flexible
              cancellation policies through our strong network of international
              hotel partners, ensuring premium accommodations for your team.
            </p>
            <button className="text-red-700 hover:text-red-800 transition duration-300">
              Learn More
            </button>
          </div>

          {/* Risk Management */}
          <div className="p-8 border rounded-lg shadow-xl bg-white">
            <FontAwesomeIcon
              icon={faShieldAlt}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Risk Management & Safety
            </h3>
            <p className="text-gray-700 mb-4">
              Minimize risks and ensure your employees’ safety with our
              proactive solutions focused on security, travel compliance, and
              real-time alerts.
            </p>
            <button className="text-red-700 hover:text-red-800 transition duration-300">
              Learn More
            </button>
          </div>

          {/* Innovation & Insights */}
          <div className="p-8 border rounded-lg shadow-xl bg-white">
            <FontAwesomeIcon
              icon={faLightbulb}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Innovation & Insights
            </h3>
            <p className="text-gray-700 mb-4">
              Unlock the power of advanced data analytics and innovative
              technologies to optimize travel efficiency and elevate the
              experience for your teams.
            </p>
            <button className="text-red-700 hover:text-red-800 transition duration-300">
              Learn More
            </button>
          </div>
        </div>
      </section>

      {/* VIP Services */}
      <section className="py-20 text-center bg-white">
        <h2 className="text-4xl font-semibold text-red-700 mb-12">
          VIP Travel Services
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 max-w-7xl mx-auto">
          <div className="p-8 border rounded-lg shadow-xl bg-white">
            <img
              src={vipServicesImg}
              alt="VIP Travel Services"
              className="w-full h-48 object-cover rounded-md mb-6"
            />
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Exclusive VIP Experiences
            </h3>
            <p className="text-gray-700 mb-4">
              Treat your executives and high-priority clients to bespoke travel
              experiences with access to luxury accommodations, private
              transportation, and tailored itineraries.
            </p>
            <button className="text-red-700 hover:text-red-800 transition duration-300">
              Learn More
            </button>
          </div>
        </div>
      </section>

      {/* Destination Expertise */}
      <section className="py-20 text-center bg-gray-50">
        <h2 className="text-4xl font-semibold text-red-700 mb-12">
          Destination Expertise
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 max-w-7xl mx-auto">
          <div className="p-8 border rounded-lg shadow-xl bg-white">
            <img
              src={destinationExpertiseImg}
              alt="Destination Expertise"
              className="w-full h-48 object-cover rounded-md mb-6"
            />
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Tailored Destination Planning
            </h3>
            <p className="text-gray-700 mb-4">
              Our destination specialists help plan memorable trips to top
              global destinations, ensuring cultural immersion, authentic
              experiences, and smooth logistics.
            </p>
            <button className="text-red-700 hover:text-red-800 transition duration-300">
              Learn More
            </button>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-20 bg-red-50 text-center">
        <h2 className="text-4xl font-semibold text-red-700 mb-12">
          Our Pricing Plans
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 max-w-6xl mx-auto">
          {pricingPlans.map((plan, index) => (
            <div
              key={index}
              className="p-8 border rounded-lg shadow-xl bg-white flex flex-col items-center"
            >
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                {plan.title}
              </h3>
              <p className="text-3xl font-medium text-red-700 mb-6">
                {plan.price}
              </p>
              <ul className="text-gray-700 mb-6">
                {plan.features.map((feature, i) => (
                  <li key={i} className="mb-3">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-red-500 mr-3"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
              <button className="bg-red-700 text-white px-6 py-3 rounded-lg hover:bg-red-800 transition duration-300">
                Select Plan
              </button>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Solutions;
