import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faShieldAlt,
  faUserShield,
  faLock,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";

const TermsAndConditions = () => {
  return (
    <div className="font-sans bg-white text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <div className="bg-red-800 text-white text-center py-20">
          <h1 className="text-6xl font-extrabold mb-6">Terms and Conditions</h1>
          <p className="text-xl max-w-3xl mx-auto">
            Read our terms and conditions carefully to understand your rights
            and obligations when using our services.
          </p>
        </div>
      </div>

      {/* Introduction Section */}
      <section className="py-16 text-center bg-red-50">
        <h2 className="text-4xl font-semibold text-red-700 mb-6">
          Introduction
        </h2>
        <p className="text-xl text-gray-700 leading-relaxed max-w-4xl mx-auto">
          Welcome to travelroy. By using our platform and services, you agree to
          comply with our terms and conditions. Please read them thoroughly.
          These terms govern your access to and use of our services, including
          any content, features, and functionalities offered by travelroy.
        </p>
      </section>

      {/* Terms of Use Section */}
      <section className="py-16 text-center bg-red-100">
        <h2 className="text-4xl font-semibold text-red-700 mb-6">
          Terms of Use
        </h2>
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center mb-8">
            <FontAwesomeIcon
              icon={faFileAlt}
              className="text-red-500 text-4xl mr-4"
            />
            <p className="text-xl text-gray-700 leading-relaxed">
              By accessing or using our services, you agree to these terms. You
              are responsible for maintaining the security of your account and
              all activities that occur under it.
            </p>
          </div>
          <div className="flex items-center mb-8">
            <FontAwesomeIcon
              icon={faLock}
              className="text-red-500 text-4xl mr-4"
            />
            <p className="text-xl text-gray-700 leading-relaxed">
              Your personal data is important to us. We employ security measures
              to protect your information and ensure it is used only for the
              purpose intended.
            </p>
          </div>
        </div>
      </section>

      {/* Privacy and Security Section */}
      <section className="py-16 text-center">
        <h2 className="text-4xl font-semibold text-red-700 mb-6">
          Privacy and Security
        </h2>
        <div className="flex flex-col md:flex-row items-center max-w-6xl mx-auto">
          <div className="md:w-1/2">
            <FontAwesomeIcon
              icon={faShieldAlt}
              className="text-red-500 text-6xl mb-6"
            />
          </div>
          <div className="md:w-1/2 md:ml-8 mt-8 md:mt-0 text-left">
            <p className="text-xl text-gray-700 leading-relaxed">
              We are committed to protecting your privacy. We collect, store,
              and process your data in accordance with applicable privacy laws
              and our privacy policy. Your information will not be shared with
              third parties without your consent, except as required by law.
            </p>
          </div>
        </div>
      </section>

      {/* User Responsibilities Section */}
      <section className="py-16 text-center bg-red-50">
        <h2 className="text-4xl font-semibold text-red-700 mb-6">
          User Responsibilities
        </h2>
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center mb-8">
            <FontAwesomeIcon
              icon={faUserShield}
              className="text-red-500 text-4xl mr-4"
            />
            <p className="text-xl text-gray-700 leading-relaxed">
              As a user, you agree to use our services responsibly. You shall
              not engage in any illegal activities, and you are solely
              responsible for the actions conducted under your account.
            </p>
          </div>
        </div>
      </section>

      {/* Limitations of Liability Section */}
      <section className="py-16 text-center bg-red-100">
        <h2 className="text-4xl font-semibold text-red-700 mb-6">
          Limitations of Liability
        </h2>
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center mb-8">
            <FontAwesomeIcon
              icon={faShieldAlt}
              className="text-yellow-500 text-4xl mr-4"
            />
            <p className="text-xl text-gray-700 leading-relaxed">
              We are not liable for any damages, loss of data, or any other
              indirect or consequential damages arising from the use or
              inability to use our services.
            </p>
          </div>
        </div>
      </section>

      {/* Termination Section */}
      <section className="py-16 text-center">
        <h2 className="text-4xl font-semibold text-red-700 mb-6">
          Termination
        </h2>
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center mb-8">
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="text-red-500 text-4xl mr-4"
            />
            <p className="text-xl text-gray-700 leading-relaxed">
              We reserve the right to suspend or terminate your account if you
              violate these terms, engage in illegal activity, or fail to comply
              with our usage policies.
            </p>
          </div>
        </div>
      </section>

      {/* Modifications to Terms Section */}
      <section className="py-16 text-center bg-red-50">
        <h2 className="text-4xl font-semibold text-red-700 mb-6">
          Modifications to Terms
        </h2>
        <p className="text-xl text-gray-700 leading-relaxed max-w-4xl mx-auto">
          We may update these terms from time to time. All updates will be
          posted on this page with an updated revision date. Your continued use
          of our services after such modifications constitutes your acceptance
          of the revised terms.
        </p>
      </section>

      {/* Final Call to Action Section */}
      <section className="py-16 bg-red-800 text-white text-center">
        <h2 className="text-4xl font-semibold mb-6">Got Questions?</h2>
        <p className="text-xl mb-6">
          If you have any questions regarding our terms and conditions, feel
          free to reach out to our support team. We're here to help!
        </p>
        <button className="bg-white text-red-800 px-8 py-4 rounded-lg shadow-md hover:bg-red-200">
          Contact Support
        </button>
      </section>
    </div>
  );
};

export default TermsAndConditions;
