import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import Toast from "components/ux/toast/Toast";
import { LOGIN_MESSAGES } from "utils/constants";

const Login = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState(false);

  const handleInputChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("Invalid email or password");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-dark-red-800 via-dark-red-900 to-black px-6">
      <div className="max-w-md w-full p-6 shadow-lg bg-gray-800 rounded-lg">
        <form
          className="p-8 bg-gray-900 rounded-lg text-white"
          onSubmit={handleLoginSubmit}
        >
          <h2 className="text-3xl font-bold text-red-300 mb-4 text-center">
            Welcome Back
          </h2>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={loginData.email}
            onChange={handleInputChange}
            className="w-full mb-4 bg-gray-700 border rounded-lg py-2 px-4 focus:outline-none"
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={loginData.password}
            onChange={handleInputChange}
            className="w-full mb-4 bg-gray-700 border rounded-lg py-2 px-4 focus:outline-none"
          />
          {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
          <button
            type="submit"
            className="w-full bg-red-300 text-black font-semibold py-2 rounded-lg hover:bg-red-400"
          >
            Log In
          </button>
          <Link
            to="/forgot-password"
            className="block text-red-300 text-center mt-4"
          >
            Forgot password?
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Login;
