import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faEnvelope,
  faMapMarkerAlt,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  return (
    <div className="font-sans bg-white text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <div className="bg-red-700 text-white text-center py-20">
          <h1 className="text-5xl font-bold mb-6">Contact Us</h1>
          <p className="text-xl mb-8">
            Have questions or need support? Reach out to us, and we'll respond
            promptly.
          </p>
        </div>
      </div>

      {/* Contact Info Section */}
      <section className="py-16 bg-red-50 text-center">
        <h2 className="text-3xl font-semibold text-red-800 mb-12">
          Get in Touch
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 max-w-6xl mx-auto">
          {/* Phone */}
          <div className="p-8 border rounded-lg shadow-lg bg-white flex flex-col items-center">
            <FontAwesomeIcon
              icon={faPhoneAlt}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">Phone</h3>
            <p className="text-gray-600">+1 (800) 55844-2444</p>
          </div>

          {/* Email */}
          <div className="p-8 border rounded-lg shadow-lg bg-white flex flex-col items-center">
            <FontAwesomeIcon
              icon={faEnvelope}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">Email</h3>
            <p className="text-gray-600">support@travelroy.it.com</p>
          </div>

          {/* Address */}
          <div className="p-8 border rounded-lg shadow-lg bg-white flex flex-col items-center">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Address
            </h3>
            <p className="text-gray-600">
              112 Travel St, Suite 50, New York, NY 5184, USA
            </p>
          </div>

          {/* Business Hours */}
          <div className="p-8 border rounded-lg shadow-lg bg-white flex flex-col items-center">
            <FontAwesomeIcon
              icon={faClock}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Business Hours
            </h3>
            <p className="text-gray-600">Mon-Fri: 9:00 AM - 6:00 PM</p>
            <p className="text-gray-600">Sat-Sun: Closed</p>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="py-16 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-12">
          Send Us a Message
        </h2>
        <div className="max-w-3xl mx-auto bg-white p-8 border rounded-lg shadow-lg">
          <form className="space-y-8">
            <div className="flex flex-col md:flex-row md:space-x-8">
              <div className="flex-1">
                <label
                  htmlFor="name"
                  className="block text-left text-gray-700 font-medium mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full p-4 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="flex-1 mt-4 md:mt-0">
                <label
                  htmlFor="email"
                  className="block text-left text-gray-700 font-medium mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-4 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
                  placeholder="Your Email"
                  required
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block text-left text-gray-700 font-medium mb-2"
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                className="w-full p-4 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="Subject"
                required
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-left text-gray-700 font-medium mb-2"
              >
                Message
              </label>
              <textarea
                id="message"
                rows="6"
                className="w-full p-4 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="Your message..."
                required
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className="w-full bg-red-700 text-white px-6 py-4 rounded-lg shadow-md hover:bg-red-800"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </section>

      {/* Map Section */}
      <section className="py-16 bg-red-50">
        <h2 className="text-3xl font-semibold text-red-700 text-center mb-12">
          Visit Us
        </h2>
        <div className="max-w-6xl mx-auto">
          <iframe
            src="https://www.google.com/maps/embed?pb=..."
            className="w-full h-96 border-0 rounded-lg shadow-md"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Contact;
