import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faHandshake,
  faUsers,
  faPlaneDeparture,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

import aboutImage from "assets/aboutImage.jpg";
import serviceImage from "assets/serviceImage.avif";
import teamImage from "assets/teamImage.jpg";
import globalImage from "assets/globalImage.webp";
import missionImage from "assets/missionImage.jpg";

const About = () => {
  return (
    <div className="font-sans bg-red-50 text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <div className="bg-red-700 text-white text-center py-20">
          <h1 className="text-6xl font-extrabold mb-6">Discover travelroy</h1>
          <p className="text-xl max-w-3xl mx-auto">
            Empowering your business with innovative B2B travel solutions. Let’s
            explore the future of seamless travel management.
          </p>
        </div>
      </div>

      {/* Company Overview Section */}
      <section className="py-16 text-center bg-red-100">
        <h2 className="text-4xl font-semibold text-red-800 mb-6">Who We Are</h2>
        <div className="max-w-6xl mx-auto">
          <img
            src={aboutImage}
            alt="About travelroy"
            className="w-full rounded-lg shadow-lg mb-8"
          />
          <p className="text-xl text-gray-700 leading-relaxed mb-8">
            travelroy is a leading B2B platform revolutionizing the way travel
            agencies operate globally. We specialize in connecting businesses to
            a vast network of travel suppliers for seamless operations and
            superior service delivery.
          </p>
          <p className="text-xl text-gray-700 leading-relaxed">
            Our mission is to elevate the travel industry by providing
            cutting-edge tools that foster growth, efficiency, and innovation.
          </p>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16 text-center bg-red-50">
        <h2 className="text-4xl font-semibold text-red-800 mb-6">
          Our Solutions
        </h2>
        <div className="flex flex-col md:flex-row items-center max-w-6xl mx-auto">
          <div className="md:w-1/2">
            <img
              src={serviceImage}
              alt="Our Services"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="md:w-1/2 md:ml-8 mt-8 md:mt-0 text-left">
            <p className="text-xl text-gray-700 leading-relaxed">
              We provide an all-in-one platform designed for modern travel
              agencies. From flight and hotel bookings to curated vacation
              packages and last-minute deals, we offer a variety of services
              tailored to meet the unique needs of B2B clients. Our solutions
              are built with the latest tech, ensuring easy access and
              management of travel services.
            </p>
            <p className="text-xl text-gray-700 leading-relaxed mt-6">
              Additionally, we offer exclusive luxury travel experiences,
              designed for the discerning traveler, and cater to businesses
              seeking innovative solutions.
            </p>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-16 text-center">
        <h2 className="text-4xl font-semibold text-red-800 mb-6">
          Meet Our Experts
        </h2>
        <div className="max-w-6xl mx-auto">
          <img
            src={teamImage}
            alt="Our Team"
            className="w-full rounded-lg shadow-lg mb-8"
          />
          <p className="text-xl text-gray-700 leading-relaxed">
            Our team comprises highly skilled professionals who are passionate
            about transforming the travel industry.
          </p>
        </div>
      </section>

      {/* Global Reach Section */}
      <section className="py-16 text-center bg-red-100">
        <h2 className="text-4xl font-semibold text-red-800 mb-6">
          Our Global Network
        </h2>
        <div className="flex flex-col md:flex-row items-center max-w-6xl mx-auto">
          <div className="md:w-1/2">
            <img
              src={globalImage}
              alt="Global Network"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="md:w-1/2 md:ml-8 mt-8 md:mt-0 text-left">
            <p className="text-xl text-gray-700 leading-relaxed">
              travelroy connects your business to a global network of travel
              suppliers. With partnerships across 50+ countries, we provide
              access to competitive pricing, exclusive deals, and first-class
              services.
            </p>
          </div>
        </div>
      </section>

      {/* Our Mission Section */}
      <section className="py-16 text-center">
        <h2 className="text-4xl font-semibold text-red-800 mb-6">Our Vision</h2>
        <div className="flex flex-col md:flex-row items-center max-w-6xl mx-auto">
          <div className="md:w-1/2">
            <img
              src={missionImage}
              alt="Our Mission"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="md:w-1/2 md:ml-8 mt-8 md:mt-0 text-left">
            <p className="text-xl text-gray-700 leading-relaxed">
              At travelroy, we aim to redefine the travel industry by offering
              businesses the tools they need to succeed.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-16 bg-red-700 text-white text-center">
        <h2 className="text-4xl font-semibold mb-6">Ready to Join Us?</h2>
        <p className="text-xl mb-6">
          Take your business to new heights with travelroy. Join our platform
          today and discover the future of travel management.
        </p>
        <button className="bg-white text-red-700 px-8 py-4 rounded-lg shadow-md hover:bg-red-200">
          Get Started
        </button>
      </section>
    </div>
  );
};

export default About;
