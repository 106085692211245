import React from "react";
import { Link } from "react-router-dom";

const FooterLink = ({ to, label }) => (
  <Link
    to={to}
    className="block text-gray-600 hover:text-red-700 transition duration-300"
  >
    {label}
  </Link>
);

const GlobalFooter = () => {
  return (
    <footer className="bg-red-800 text-gray-200 py-16">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-4">
            <h4 className="text-lg font-semibold text-white">Company</h4>
            <FooterLink to="/about-us" label="About Us" />
            <FooterLink to="/contact" label="Contact" />
            <FooterLink to="/terms" label="Terms" />
            <FooterLink to="/privacy-policy" label="Privacy Policy" />
          </div>

          {/* Technology */}
          <div className="space-y-4">
            <h4 className="text-lg font-semibold text-white">Technology</h4>
            <FooterLink to="/solutions" label="Solutions" />
          </div>

          {/* Stay Updated */}
          <div className="md:col-span-2">
            <h4 className="text-lg font-semibold text-white">Stay Updated</h4>
            <p className="text-sm text-gray-400">
              Subscribe to our newsletter for the latest updates and insights.
            </p>
            <form className="flex mt-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full p-3 rounded-l-lg bg-white border border-gray-300 focus:outline-none"
              />
              <button className="px-6 py-3 bg-red-700 hover:bg-red-800 text-white font-medium rounded-r-lg transition duration-300">
                Subscribe
              </button>
            </form>
          </div>
        </div>

        <div className="text-center mt-10 border-t border-gray-700 pt-6">
          <p className="text-sm text-gray-400">Designed by travelroy</p>
          <p className="text-sm text-gray-400">
            &copy; {new Date().getFullYear()} travelroy. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default GlobalFooter;
